
// Libraries
import * as React from 'react'
import moment from 'moment'
import Classnames from 'classnames'

// Components
import Modal from '../../modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt, faEllipsisH, faTimes, faTrashRestore } from '@fortawesome/pro-solid-svg-icons'
import AdminUserViewRow from '../users/adminUserViewRow'
import Button from '../../button'
import ReactTooltip from 'react-tooltip'

class AdminArtistView extends React.Component {
	state = {
		showBadge: false,
		openActionMenu: false,
	}

	render() {
		const { artist, handleClose, handleOpenEdit, handleConfirmDelete, handleRestoreArtist } = this.props

		const actionsMenuClasses = Classnames([
			'admin-view__actions-menu',
			{
				'admin-view__actions-menu--open': this.state.openActionMenu,
			},
		])

		return <Modal handleClose={ handleClose } modalRight>
			<div className="admin-view admin-view--artist">
				<div className="admin-view__header">
					<div className="column">
						<h2>Artist Profile</h2>
						<h3>{ artist?.name }</h3>
						<p>Created: { moment(artist?.created_at).format('DD MMM YYYY') }</p>
					</div>
					<div className="column">
						<div className={ actionsMenuClasses }>
							<Button onClick={ () => this.setState({ openActionMenu: !this.state.openActionMenu }) } iconOnly data-tip data-for="tooltip-open-actions-menu">
								<FontAwesomeIcon icon={ faEllipsisH } title="Ellipsis vertical icon" />
								<FontAwesomeIcon icon={ faTimes } title="Times icon" />
								<span className="sr-only">Toggle Artist Actions</span>
							</Button>
							<ReactTooltip id="tooltip-open-actions-menu" place="top" backgroundColor="#005EB8" textColor="#fff">
								<span>Toggle Artist Actions</span></ReactTooltip>

							<ul>
								<li><Button onClick={ () => handleOpenEdit(artist?.id) } iconOnly>
									<FontAwesomeIcon icon={ faEdit } title="Edit icon" />
									<span className="text">Edit Artist</span>
								</Button></li>
								{(parseInt(artist?.events_count) === 0 && !artist?.deleted_at) && <li><Button onClick={ () => handleConfirmDelete(artist?.id) } iconOnly danger>
									<FontAwesomeIcon icon={ faTrashAlt } title="Delete icon" />
									<span className="text">Delete Artist</span>
								</Button></li>}
								{artist?.deleted_at && <li><Button onClick={ () => handleRestoreArtist(artist?.id) } iconOnly>
									<FontAwesomeIcon icon={ faTrashRestore } title="Restore icon" />
									<span className="text">Restore Artist</span>
								</Button></li>}
							</ul>
						</div>
					</div>
				</div>
				<div className="admin-view__view">
					<AdminUserViewRow label="Image" value={artist?.image
						? <img src={artist.image} className='feature-image' alt={artist.name} />
						: '-'} stacked />
					<AdminUserViewRow label="Name" value={ artist?.name } />
					<AdminUserViewRow label="Description" value={ artist?.description } />
					<AdminUserViewRow label="Events Count" value={ artist?.events_count || '-' } />
				</div>
			</div>
		</Modal>
	}
}

export default AdminArtistView
