
// Libraries
import * as React from 'react'

// Components
import AdminArtistsTableRow from "./adminArtistsTableRow"
import TableSort from '../tableSort'

class AdminArtistsTable extends React.Component {
	state = {
		artists: [],
		activeFilters: {}
	}

	render() {
		const {artists, emptyMessage, handleOpenView, getArtists} = this.props

		return (
			<div className='admin-table admin-table--artists wrap--scrollx'>
				<table className="admin-table__table">
					<thead>
						<tr>
							<th>Name <TableSort fieldName="name" refreshData={ getArtists } /></th>
							<th>URL</th>
							<th>Events</th>
							<th className="actions"><span className="sr-only">Actions</span></th>
						</tr>
					</thead>
					<tbody>
					{
						artists?.length
							? artists.map((artist) => <AdminArtistsTableRow key={artist.id} artist={artist} handleOpenView={handleOpenView} />)
							: <tr><td colSpan={100} align="center">{emptyMessage}</td></tr>
					}
					</tbody>
				</table>
			</div>
		)
	}
}

export default AdminArtistsTable
