
// Libraries
import * as React from 'react'

// Components
import Modal from "../../modal"
import Button from "../../button";

class AdminArtistConfirmDelete extends React.Component {
	state = {
		inputValue: '',
		inputError: ''
	}

	render() {
		const {artist, handleClose, handleConfirm} = this.props

		return <Modal handleClose={handleClose} modalConfirm>
			<div className="modal-confirm">
				<h2>Remove {artist.name}?</h2>
				<p>The Artist will be removed from related events, leaving them events without an Artist.</p>
				<ul className="button-wrap">
					<li><Button onClick={handleClose} hollow colorEndeavour>
						Back
					</Button></li>
					<li><Button onClick={() => handleConfirm()} colorPomegranate>
						Remove Artist
					</Button></li>
				</ul>
			</div>
		</Modal>
	}
}

export default AdminArtistConfirmDelete
