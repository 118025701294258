
// Libraries
import * as React from 'react'

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import InputFactory from "../../inputFactory"
import { faSearch } from '@fortawesome/pro-regular-svg-icons'

class AdminUsersTableHeader extends React.Component {
	render() {
		const {titleIcon, subTitleText, titleText, infoText, searchValue, searchHandler} = this.props

		return <div className="admin-table__header">
			<div className="column column--title">
				{subTitleText
					? <h1>
						<svg xmlns="http://www.w3.org/2000/svg" width="58.366" height="53.873" viewBox="0 0 58.366 53.873">
							<g transform="translate(-199 -145.127)">
								<path className="a"
									  d="M5347.5,1258.259v-5.63l1.339-.773a1.826,1.826,0,0,0,.669-2.5l-7.922-13.721a1.826,1.826,0,0,0-2.5-.668l-22.165,12.8a1.81,1.81,0,0,0-.566.51H5308.1a3.652,3.652,0,0,1,1.634-1.99L5341.4,1228a3.654,3.654,0,0,1,4.994,1.338l3.655,6.333a3.656,3.656,0,1,0,3.656,6.333l3.657,6.334a3.656,3.656,0,0,1-1.338,4.995l-8.525,4.922Zm-.443-8.189h0a3.505,3.505,0,0,0-3.057-1.794h-23.083l18.864-10.891,7.313,12.665-.036.021Z"
									  transform="translate(-5101 -1081.887)"/>
								<path className="b"
									  d="M9.75-21.937V-7.312H34.125V-21.937Zm30.469,7.312a3.656,3.656,0,0,1,3.656-3.656v-7.312a3.656,3.656,0,0,0-3.656-3.656H3.656A3.656,3.656,0,0,0,0-25.594v7.312a3.656,3.656,0,0,1,3.656,3.656A3.656,3.656,0,0,1,0-10.969v7.312A3.656,3.656,0,0,0,3.656,0H40.219a3.656,3.656,0,0,0,3.656-3.656v-7.312A3.656,3.656,0,0,1,40.219-14.625Zm-3.656-7.922V-6.7a1.828,1.828,0,0,1-1.828,1.828H9.141A1.828,1.828,0,0,1,7.312-6.7V-22.547a1.828,1.828,0,0,1,1.828-1.828H34.734A1.828,1.828,0,0,1,36.562-22.547Z"
									  transform="translate(199 199)"/>
							</g>
						</svg>
						<small>{subTitleText}</small><br/>
						{titleText}
					</h1>
					: <h1><FontAwesomeIcon icon={titleIcon} /> {titleText}</h1>
				}
				{(infoText && infoText !== '') && <p>{infoText}</p>}
			</div>
			<div className="column column--search">
				<form>
					<FontAwesomeIcon icon={faSearch} className="search-icon" />
					<InputFactory
						type="search"
						name="search"
						placeholder="Search"
						value={searchValue}
						onChange={searchHandler}
						onKeyDown={this.handleOnKeyDown}
					/>
				</form>
			</div>
		</div>
	}

	handleOnKeyDown = (keyDownEvent) => {
		if(keyDownEvent.keyCode === 13) {
			keyDownEvent.preventDefault();
			return false;
		}
	}
}

export default AdminUsersTableHeader
