
// Libraries
import * as React from 'react'
import _ from 'lodash'
import qs from 'qs'

// Services
import ApiService from '../../../services/api'

// Context
import NotificationContext from '../../../contexts/notification'

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import AdminUsersTableHeader from '../../../components/admin/users/adminUsersTableHeader'
import Pagination from '../../../components/pagination'
import AdminArtistsTable from '../../../components/admin/artists/adminArtistsTable'
import AdminArtistView from '../../../components/admin/artists/adminArtistView'
import AdminArtistEdit from '../../../components/admin/artists/adminArtistEdit'
import AdminArtistConfirmDelete from '../../../components/admin/artists/adminArtistConfirmDelete'
import {faUserMusic} from '@fortawesome/pro-solid-svg-icons'
import Button from '../../../components/button'

class AdminArtistsIndexPage extends React.Component {
	state = {
		activeFilters: {
			'filter': {},
		},
		users: [],
		pagination: [],
		emptyMessage: 'Retrieving artist data ...',
		selectedArtist: {},
		modalViewShow: false,
		modalEditShow: false,
		modalConfirmDeleteShow: false,
	}

	static contextType = NotificationContext

	render() {
		const {activeFilters, artists, pagination, emptyMessage, selectedArtist, modalViewShow, modalConfirmDeleteShow, modalEditShow} = this.state

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-table page--admin-table-artists nav-blue-half">
				<Seo title="Artists Management" />
				<div className="admin-table__wrap">
					<AdminUsersTableHeader
						titleText="Artists Management" titleIcon={faUserMusic}
						searchValue={activeFilters.search} searchHandler={_.debounce(this.handleSearchChange, 500)} />

					<p className='filters-status'>
						Filter by:
						<a href="/admin/artists/" className={activeFilters?.filter?.is_published === '1' && 'active'}>Published</a>
						<a href="/admin/artists/?filter[is_published]=0" className={activeFilters?.filter?.is_published === '0' && !activeFilters?.filter?.trashed  && 'active'}>Draft</a>
						<a href="/admin/artists/?filter[is_published]=0&filter[trashed]=only" className={activeFilters?.filter?.is_published === '0' && activeFilters?.filter?.trashed === 'only' && 'active'}>Deleted</a>
					</p>

					<div className="buttons-wrap">
						<Button to="/admin/artists/new" colorEndeavour>New Artist</Button>
					</div>

					<Pagination pagination={pagination} handlePaginationClick={this.handlePaginationClick} />

					<AdminArtistsTable
						artists={artists}
						getArtists={this.getArtists}
						emptyMessage={emptyMessage}
						handleOpenView={this.handleOpenView} />

					{modalViewShow && <AdminArtistView
						artist={selectedArtist}
						handleClose={() => this.setState({modalViewShow: false})}
						handleOpenEdit={this.handleOpenEdit}
						handleConfirmDelete={this.handleConfirmDelete}
						handleRestoreArtist={this.handleRestoreArtist}
					/>}

					{modalEditShow && <AdminArtistEdit
						artist={selectedArtist}
						handleClose={() => this.setState({
							modalEditShow: false,
							modalViewShow: true
						})}
						handleEditComplete={this.handleEditComplete} />}

					{modalConfirmDeleteShow && <AdminArtistConfirmDelete
						artist={selectedArtist}
						handleClose={() => this.setState({modalConfirmDeleteShow: false})}
						handleConfirm={() => this.handleProcessDelete(selectedArtist.id)} />}
				</div>
			</LayoutAdmin>
		</PrivateRoute>
	}

	componentDidMount() {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		const searchParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

		Promise.all([
			this.getArtists({...activeFilters, ...searchParams}, false)
				.catch(() => addNotification('There was an error fetching the artists.', 'error'))
		]).then(() => {
			this.setState({
				activeFilters:{...activeFilters, ...searchParams},
				emptyMessage: 'No artists found, try adjusting your filters'
			})
		})
	}

	getArtists = (queryParams) => {
		const apiService = new ApiService()
		queryParams = {...this.state.activeFilters, ...queryParams}
		queryParams['paginate'] = 1
		queryParams['counts'] = ['events']

		if (!queryParams['filter']['is_published']) {
			queryParams['filter']['is_published'] = "1"
		}
		
		return apiService.get('artists', {}, queryParams).then( (response) => {
			this.setState({
				artists: response.data.data,
				pagination: response.data,
				activeFilters: queryParams
			})
		}).catch(err => console.error(err))
	}

	handleSearchChange = (searchValue) => {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		if (searchValue) {
			activeFilters['search'] = searchValue
		} else if (activeFilters['search']) {
			delete activeFilters['search']
		}

		if (activeFilters['page']) {
			delete activeFilters['page']
		}

		this.setState({activeFilters}, () => {
			this.getArtists(activeFilters)
				.catch(() => addNotification('There was an error filtering the artists.', 'error'))
		})
	}

	handlePaginationClick = (page) => {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		activeFilters['page'] = page

		this.setState({activeFilters}, () => {
			this.getArtists(activeFilters)
				.catch(() => addNotification('There was an error filtering the artists.', 'error'))
		})
	}

	handleRestoreArtist = (artist_id) => {
		const apiService = new ApiService()
		return apiService.put(`artists/${artist_id}/restore`)
			.then( () => {
				this.setState({
					modalViewShow: false
				})
				this.getArtists()
			})
			.catch(err => console.error(err))
	}


	handleOpenView = (artist_id) => {
		const apiService = new ApiService()
		return apiService.get(`artists/${artist_id}`)
			.then( (response) => {
				this.setState({
					selectedArtist: response.data,
					modalViewShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleOpenEdit = (artist_id) => {
		const apiService = new ApiService()
		return apiService.get(`artists/${artist_id}`)
			.then( (response) => {
				this.setState({
					selectedArtist: response.data,
					modalEditShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleConfirmDelete = () => {
		this.setState({
			modalConfirmDeleteShow: true
		})
	}

	handleProcessDelete = (artist_id) => {
		const {addNotification} = this.context
		const apiService = new ApiService()

		apiService.delete(`artists/${artist_id}`)
			.then( (response) => {
				addNotification('Artist deleted successfully.', 'success')
				this.updateStateArtists(response.data, true)
				this.setState({
					modalConfirmDeleteShow: false,
					modalViewShow: false
				})
			})
			.catch(err => console.error(err))
	}


	handleEditComplete = (artist) => {
		this.setState({
			modalEditShow: false,
			modalViewShow: true
		})
		
		this.updateStateSelectedArtist(artist)
		this.updateStateArtists(artist)
	}

	updateStateSelectedArtist = (artist) => {
		this.setState(prevState => ({
			selectedArtist: {...prevState.selectedArtist, ...artist}
		}))
	}

	updateStateArtists = (artist, removeArtist = false) => {
		const {artists} = this.state

		// update artists state
		artists.forEach((existingArtist, key) => {
			if (existingArtist.id === artist.id) {
				if (removeArtist) {
					artist.splice(key, 1)
				}
				else {
					artists[key] = {...existingArtist, ...artist}
				}
			}
		})

		this.setState({artist})
	}
}

export default AdminArtistsIndexPage
