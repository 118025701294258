
// Libraries
import * as React from 'react'
import moment from "moment"

// Services
import ApiService from "../../../services/api"
import ArtistService from '../../../services/artist'

// Components
import Modal from "../../modal"
import AdminVenueViewRow from "../venues/adminVenueViewRow"
import InputFactory from "../../inputFactory"
import Button from "../../button"

// Context
import NotificationContext from '../../../contexts/notification'

class AdminArtistEdit extends React.Component {
	state = {
		initialValues: {},
		inputValues: {},
		inputErrors: {},
		isSubmitting: false
	}

	static contextType = NotificationContext

	render() {
		const {artist, handleClose} = this.props
		const {initialValues, inputValues, inputErrors, isSubmitting} = this.state

		return <Modal handleClose={handleClose} modalRight adminForm>
			<div className="admin-form admin-form--artists">
				<div className="admin-form__header">
					<div className="column">
						<h3>{artist.name}</h3>
						<p>Added: {moment(artist.created).format('DD MMM YYYY')}</p>
					</div>
					<div className="column">
					</div>
				</div>
				<div className="admin-form__form">
					<form onSubmit={this.handleSubmit} encType="multipart/form-data">
						<div className="admin-form__field-group">
							<h2>Information</h2>
							<AdminVenueViewRow
								label="Name*"
								value={<InputFactory
									type="text"
									name="name"
									value={inputValues?.name}
									error={inputErrors?.name}
									onChange={this.handleInputChange}/>}
							/>
							<AdminVenueViewRow
								label="Description*"
								value={<InputFactory
									type="tinymce"
									name="description"
									initialValue={initialValues?.description}
									value={inputValues?.description}
									error={inputErrors?.description}
									onChange={this.handleInputChange}/>}
							/>
							<AdminVenueViewRow
								label={<>Image*<br/><small>Size: 640px x 780px</small></>}
								value={<InputFactory
									type="file"
									name="image"
									initialFilePreview={inputValues?.imagePreview || null}
									value={inputValues?.image}
									error={inputErrors?.image}
									onChange={this.handleFileChange}/>}
							/>
							<AdminVenueViewRow
								checkbox
								label=""
								value={<InputFactory
									type="checkbox"
									name="is_published"
									label={<>
										Publish Artist's Landing Page?<br/>
										<small>If unchecked, artist will be available in draft & no landing
											page will be published in the website</small>
									</>}
									checked={inputValues?.is_published}
									value={inputValues?.is_published}
									error={inputErrors?.is_published}
									onChange={this.handleFileChange}
									checkboxEndeavour/>}
							/>
						</div>

						<div className="admin-form__submit-wrap">
							<Button type="button" onClick={handleClose} colorEndeavour hollow>Discard Changes</Button>
							<Button type="submit" isLoading={isSubmitting} colorEndeavour>Save Changes</Button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	}

	async componentDidMount() {
		const {artist} = this.props
		const {inputValues} = this.state
		const initialValues = {...artist}

		inputValues.name = artist.name
		inputValues.description = artist.description
		inputValues.imagePreview = artist.image
		inputValues.is_published = artist.is_published

		this.setState({initialValues, inputValues})
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues} = this.state

		if (value !== undefined) {
			// Update field value
			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleFileChange = (value, name) => {
		// Get current form data from state
		const {inputValues} = this.state

		// Update state of file input values
		inputValues[name] = value
		this.setState({ inputValues })
	}

	handleSubmit = async (submitEvent) => {
		submitEvent.preventDefault()
		this.setState({
			isSubmitting: true
		})

		const {inputValues} = this.state
		const {artist, handleEditComplete} = this.props
		const apiService = new ApiService()
		const artistService = new ArtistService()
		const {addNotification} = this.context

		const formData = artistService.formData(inputValues)
		formData.set('is_published', inputValues.is_published ? 1 : 0)

		await apiService.post(`artists/${artist.id}`, {body: formData}, true)
			.then(async (response) => {
				if (response.success) {
					handleEditComplete(response.data);
					addNotification('Artist Updated', 'success');
				}
				else {
					this.setState({
						inputErrors: response.errors,
						isSubmitting: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}

export default AdminArtistEdit
