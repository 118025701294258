
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons'

class AdminArtistsTableRow extends React.Component {
	render() {
		const {artist, handleOpenView} = this.props

		let classNames = Classnames([
			'admin-table__row'
		].concat([artist.deleted_at !== null && 'admin-table__row--deleted']))

		return <tr className={classNames}>
			<td className="admin-table__cell--name">{artist.name}</td>
			<td className="admin-table__cell--url"><a href={`/artists/${artist.slug}`} target="_blank" rel="noreferrer">/artists/{artist.slug}</a></td>
			<td className="admin-table__cell--events-count">{artist.events_count || '-'}</td>
			<td className="admin-table__cell--actions">
				<button onClick={() => handleOpenView(artist.id)}>
					<FontAwesomeIcon icon={faArrowRight} title="Edit icon" /><span className="sr-only"> View/Edit</span>
				</button>
			</td>
		</tr>
	}
}

export default AdminArtistsTableRow
